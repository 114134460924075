<template>
  <div class="relative h-screen md:h-[75vh] xl:min-h-[60rem] xl:max-h-[90vh] overflow-hidden flex justify-center">
    <div class="absolute inset-0 bg-gray-800 opacity-60 w-full h-full z-[1]" />
    <Splide
      ref="$splide"
      class="w-full h-full"
      :has-track="false"
      :options="splideConfig"
      @splide:move="onMove"
    >
      <SplideTrack class="h-full">
        <SplideSlide
          v-for="(slide, idx) in slides"
          :key="idx"
        >
          <img
            :src="slide.imageSrc"
            :alt="slide.label"
            class="object-cover w-full h-full min-h-[40vh] block"
            loading="lazy"
          >
        </SplideSlide>
      </SplideTrack>
    </Splide>

    <BaseSection class="absolute top-0 flex items-start justify-center md:justify-end pr-4 md:pr-32 w-full h-full text-brand-white transition-all duration-300 z-[2]">
      <BaseAccordion class="flex flex-col gap-4 max-w-2xl xl:min-w-[45rem] md:min-w-full min-w-full" :active-index="activeItem">
        <BaseAccordionItem
          v-for="(item, index) in slides"
          :key="index"
          :chevron="false"
          class="cursor-pointer transition-all duration-300 ease-in-out"
          :class="activeItem === index ? '' : 'opacity-50 hover:opacity-100 transition-opacity duration-300'"
          borderless
          @click="goToSlide(index)"
        >
          <template #trigger>
            <div class="flex items-end">
              <div class="text-sm mr-2">
                0{{ index +1 }}
              </div>
              <div class="text-4xl font-semibold">
                {{ item.label }}
              </div>
            </div>
          </template>
          <div class="pt-6 ml-6 line-clamp-6">
            <BaseParagraph :data="item.body" class="text-brand-white" />
          </div>
        </BaseAccordionItem>
      </BaseAccordion>
    </BaseSection>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { SplideTrack, Splide, SplideSlide } from '@splidejs/vue-splide';
import { BaseAccordion, BaseAccordionItem } from '../accordion';
import type { SliderItem } from '~/types';
import BaseParagraph from '~/components/BaseParagraph.vue';
import BaseSection from '~/components/BaseSection.vue';

interface Props {
  slides: SliderItem[]
}
defineProps<Props>();

const $splide = ref(null) as any;
const activeItem = ref(0);
const splideConfig = {
  perPage: 1,
  type: 'fade',
  arrows: false,
  pagination: false,
  autoplay: true,
  rewind: true,
};

function goToSlide (index: number) {
  $splide.value.go(index);
}

function onMove (_: unknown, activeIndex: number) {
  activeItem.value = Number(activeIndex);
}
</script>
